/**
 * @author Salma Hefnawy
 * @date 2024-02-06
 * @description Implementation of analytics events related utilities.
 * @filename analytics-events.ts
 */

import { CommonEventParameters } from '@orascom/utils';

export interface UnitEventParameters {
  position?: string;
  unitId?: string;
}

export function analyticsHeaderTalkToSalesClickEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'talk_tosales', params);
  } catch (e) {
    console.error('Error in analyticsHeaderTalkToSalesClickEvent', e);
  }
}

export function analyticsHeaderWishListClickEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'open_favorites', params);
  } catch (e) {
    console.error('Error in analyticsHeaderWishListClickEvent', e);
  }
}

export function analyticsHeaderSearchClickEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'open_favorites', params);
  } catch (e) {
    console.error('Error in analyticsHeaderSearchClickEvent', e);
  }
}

export function analyticsSideBarCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'sidebar', params);
  } catch (e) {
    console.error('Error in analyticsSideBarCustomEvent', e);
  }
}

export function analyticsSideBarCountriesCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'countries', params);
  } catch (e) {
    console.error('Error in analyticsSideBarCountriesCustomEvent', e);
  }
}

export function analyticsSideBarCountrySelectionCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `countries_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsSideBarCountrySelectionCustomEvent', e);
  }
}

export function analyticsSideBarDestinationsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'destinations', params);
  } catch (e) {
    console.error('Error in analyticsSideBarDestinationsCustomEvent', e);
  }
}

export function analyticsSideBarDestinationSelectionCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `destinations_${params.name}`, params);
  } catch (e) {
    console.error(
      'Error in analyticsSideBarDestinationSelectionCustomEvent',
      e
    );
  }
}

export function analyticsSideBarInvestmentCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'investment', params);
  } catch (e) {
    console.error('Error in analyticsSideBarInvestmentCustomEvent', e);
  }
}

export function analyticsSideBarAboutUsCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'aboutus', params);
  } catch (e) {
    console.error('Error in analyticsSideBarAboutUsCustomEvent', e);
  }
}

export function analyticsGetAssistanceCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', 'get_assistance', params);
  } catch (e) {
    console.error('Error in analyticsGetAssistanceCustomEvent', e);
  }
}

export function analyticsLearnMoreCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'learnmore', params);
  } catch (e) {
    console.error('Error in analyticsLearnMoreCustomEvent', e);
  }
}

export function analyticsContactUsCustomEvent(params: CommonEventParameters) {
  try {
    gtag('event', 'contactus', params);
  } catch (e) {
    console.error('Error in analyticsContactUsCustomEvent', e);
  }
}

export function analyticsExploreDestinationCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `explore_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsAddToCompareCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `addto_compare_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsUnitsAddedToCompareCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `compare`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsAddToFavoritesCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `addto_favorites_${params.name}`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsReserveUnitCustomEvent(params: UnitEventParameters) {
  try {
    gtag('event', `reserve_${params.position}`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsInterestedUnitCustomEvent(
  params: UnitEventParameters
) {
  try {
    gtag('event', `interested`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsDownloadUnitSaleOfferCustomEvent(
  params: UnitEventParameters
) {
  try {
    gtag('event', `download_salesoffer`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsMoreAboutDestinationCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `moreabout_destination`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsRequestSalesCallCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `request_salescall`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsDownloadBrochureCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `download_brochure_bottom`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsExplorePropertiesCustomEvent(
  params: CommonEventParameters
) {
  try {
    gtag('event', `explore_properties`, params);
  } catch (e) {
    console.error('Error in analyticsExploreDestinationCustomEvent', e);
  }
}

export function analyticsSignupEvent(params: CommonEventParameters) {
  try {
    gtag('event', `signup`, params);
  } catch (e) {
    console.error('Error in analyticsSignupEvent', e);
  }
}
