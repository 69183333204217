import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { NavyButton } from '../common/button/button';
import ExploreIndicator from '../common/explore-indicator/explore-indicator';
import { routeSearchParamsConstructor } from '@orascom/utils';
import styles from './destination-banner.module.scss';

/* eslint-disable-next-line */
export interface DestinationBannerProps {
  image: string;
  title: string;
  subTitle: string;
  logo: string;
  destinationName: string;
  destinationId: string;
  destinationCountryName: string;
  destinationCountryId: string;
  scrollTitle: string;
}

export function DestinationBanner(props: Readonly<DestinationBannerProps>) {
  const { t } = useTranslation();

  return (
    <div
      className={styles['wrapper']}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6951374299719888) 0%, rgba(0,0,0,0) 100%), url(${props.image})`,
      }}
    >
      <div className={`${styles['content']} container`}>
        {props.logo ? (
          <div className={styles['logo']}>
            <img src={props.logo} alt={props.destinationName} loading="lazy" />
          </div>
        ) : null}
        <h1>{props.title}</h1>
        <p>{props.subTitle}</p>

        <div className={styles['buttons-wrapper']}>
          <NavyButton
            asLink
            to={ROUTES.AvailableUnits.path}
            searchParams={routeSearchParamsConstructor(
              [
                {
                  value: props.destinationCountryId,
                  label: props.destinationCountryName,
                },
              ],
              [
                {
                  value: props.destinationId,
                  label: props.destinationName,
                },
              ]
            ).toString()}
            secondary
          >
            {t('seeAvailableUnits')}
          </NavyButton>
        </div>
      </div>

      <ExploreIndicator
        title={props.scrollTitle}
        className={styles['scroll']}
      />
    </div>
  );
}

export default DestinationBanner;
