import {
  FiltersEnums,
  FilterValue,
  FiltersObjectInterface,
  OptionValue,
  Payload,
  CountryInfo,
  Destination,
} from '@orascom/api-interfaces';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import * as queryString from 'query-string';

export function filterArrOfObjectsByKeyValue(
  arrayOfObjects: Record<string, unknown>[],
  key: string,
  value: string
) {
  return arrayOfObjects.filter((obj) => obj[key] === value)?.[0];
}

/**
 *check if this value not null and not undefined.
 *
 * @export
 * @param {unknown} value
 * @returns {boolean}
 */
export function exist(value: unknown): boolean {
  let doExist = true;
  if (value === 'undefined' || value === null || value === undefined) {
    doExist = false;
  }

  return doExist;
}

/**
 * get data from payload.
 *
 * @export
 * @template T Type of the payload.
 * @param {Payload<T>} payload Payload to extract data from.
 * @returns {Promise<T>} Promise to return the data from the payload.
 */
export function getPayloadData<T extends unknown = unknown>(
  payload: Payload<T>
): Promise<T> {
  if (!exist(payload)) {
    return Promise.reject();
  }

  if (!exist(payload.data)) {
    return Promise.reject(payload.errors);
  }

  return Promise.resolve(payload.data);
}

/**
 * handle errors occurred in the system before
 * displaying them to the user.
 *
 * @param {*} error an error happened in the process.
 * @returns {Array<string>} array of error messages to be displayed.
 */
export function handleError(error: unknown): string[] {
  let errors: string[] = [];

  switch (typeof error) {
    case 'string':
      errors.push(error);
      break;
    case 'object':
      for (const key in error) {
        errors = [...errors, ...handleError(Object(error)[key])];
      }
      break;
    default:
      errors.push('Something went wrong, please try again later.');
  }

  return errors;
}

export const mapResponseToFilterValues = (values: FilterValue[]) => {
  const result = values.map((v) => {
    return {
      label: v.name,
      value: v.id,
    };
  });
  return result;
};

export const mapCountriesResponseToFilterValues = (
  values: Pick<CountryInfo, 'id' | 'name' | 'slug'>[] | undefined
) => {
  const result =
    values?.map((v) => {
      return {
        label: v.name,
        value: v.slug,
      };
    }) ?? [];
  return result;
};

export const mapDestinationsResponseToFilterValues = (
  values: Destination[] | undefined
): { label: string; value: string }[] => {
  return (
    values?.map((v) => ({
      label: v.name,
      value: v.slug ?? '',
    })) ?? []
  );
};

/**
 * return year quarter from time stamp
 *
 * @param {number} timestamp
 * @returns {string} quarter number followed by year.
 */
export function getYearQuarter(timestamp: number) {
  const date = new Date(timestamp * 1000);

  return `${Math.floor(date.getMonth() / 3 + 1)} ${date.getFullYear()}`;
}

/**
 * checking if the min value is bigger than the max value
 *
 * @param {RangeInterface} range
 * @returns
 */
export const rangeCheckHandler = (range: { min: number; max: number }) => {
  if (range.min && range.max && Number(range.max) < Number(range.min)) {
    return false;
  }
  return true;
};

/**
 * calculating the time difference of a given timestamp
 *
 * @param {number} timestamp
 * @returns
 */
export const notificationTimeCalculator = (timestamp: number) => {
  const targetTime = Number(timestamp) * 1000;
  const dateNow = Date.now();
  const timeDifference = dateNow - targetTime;
  if (timeDifference <= 0) {
    return;
  }
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (days) {
    return `${days} ${t('daysAgo')}`;
  }
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  if (hours) {
    return `${hours} ${t('hoursAgo')}`;
  }
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  if (minutes) {
    return `${minutes} ${t('minutesAgo')}`;
  }
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  return `${seconds} ${t('secondsAgo')}`;
};

/**
 * handles converting the file to base64
 * to pass it to the server in the correct format
 * @param file File
 * @returns Promise<string>
 */
export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

/**
 * handles uploading files
 *
 * @param {React.ChangeEvent<HTMLInputElement>} event
 */
export const onFileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setSelectedFilename: (arg: string) => void,
  setSelectedFile: (arg: File) => void
) => {
  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    const fileSize = file.size / (1024 * 1024); // in MB
    // check file size -> if greater than 3MB -> reject selection
    if (fileSize > 3) {
      // clear selection
      event.target.value = '';
      // show toastr error
      toast.error('File size should not exceed 3 MB');
    } else {
      setSelectedFilename(file.name);
      setSelectedFile(file);
    }
  }
};

/**
 * extract set of values assigned to a specific key
 * in Fragment part in the url.
 *
 * @param {string} key key/name of the parameter.
 * @param {string} search search part in the UrlFragment.
 * @returns {Array<string>} values related to the provided key.
 */
export function getParamFromSearchUrl(key: string, search: string): string[] {
  let paramValues: string[] = [];
  let params: string[];

  if (exist(key) && exist(search) && search.length > 0) {
    params = search.startsWith('?')
      ? search.substring(1).split('&')
      : search.split('&');
    paramValues = params.filter((param: string): boolean => {
      return param.startsWith(`${key}=`);
    });
  }
  return paramValues.map((paramValue: string): string => {
    return paramValue.substring(key.length + 1);
  });
}

/**
 * handles displaying error messages
 *
 * @param {string[]} errorsArray
 */
export const errorMessagesHandler = (errorsArray: string[]) => {
  errorsArray.forEach((error) => toast.error(error));
};

/**
 * Array of the filters that clears all the following filters selected arranged by order
 */
const effectiveFilters = [
  FiltersEnums.COUNTRIES,
  FiltersEnums.DESTINATIONS,
  FiltersEnums.PROJECTS,
  FiltersEnums.UNIT_TYPES,
];

/**
 * function that handles clearing all the filters following the modified filter
 *
 * @param {FiltersEnums} filterKey
 * @param {queryString.ParsedQuery<string>} [allFilters]
 * @returns
 */
export const resetEffectiveFiltersHandler = (
  filterKey: FiltersEnums,
  allFilters?: queryString.ParsedQuery<string>
) => {
  if (!allFilters) {
    return;
  }
  const targetedIndex = effectiveFilters.indexOf(filterKey);
  const unremovedFiltersArr = effectiveFilters.slice(0, targetedIndex);
  if (!unremovedFiltersArr.length) {
    return;
  }
  Object.keys(allFilters).forEach((key) => {
    if (!unremovedFiltersArr.includes(key as FiltersEnums)) {
      delete allFilters[key as keyof FiltersObjectInterface];
    }
  });
  return allFilters;
};

/**
 * function to construct the filter parameters to navigate to units and filters page
 *
 * @param {OptionValue[]} [countries]
 * @param {OptionValue[]} [destinations]
 * @param {OptionValue[]} [projects]
 * @param {OptionValue[]} [unitTypes]
 * @param {boolean} [onResale]
 * @param {FilterValue[]} [tags]
 * @param {RangeInterface} [price]
 * @returns
 */
const getValuesQuery = (options: OptionValue[]) => {
  return options.map((o) => o.value).join(', ');
};
export const routeSearchParamsConstructor = (
  countries?: OptionValue[],
  destinations?: OptionValue[],
  projects?: OptionValue[],
  unitTypes?: OptionValue[],
  onResale?: boolean,
  tags?: FilterValue[]
) => {
  const searchParams = new URLSearchParams();
  if (countries?.length) {
    searchParams.set(FiltersEnums.COUNTRIES, getValuesQuery(countries));
  }
  if (destinations?.length) {
    searchParams.set(FiltersEnums.DESTINATIONS, getValuesQuery(destinations));
  }
  if (projects?.length) {
    searchParams.set(FiltersEnums.PROJECTS, getValuesQuery(projects));
  }
  if (unitTypes?.length) {
    searchParams.set(FiltersEnums.UNIT_TYPES, getValuesQuery(unitTypes));
  }
  if (onResale) {
    searchParams.set(FiltersEnums.ON_RESALE, '1');
  }
  if (tags?.length) {
    const tagsOptionsArr = tags?.map((tag) => {
      return {
        label: tag.name,
        value: tag.id,
      };
    });
    searchParams.set(FiltersEnums.TAGS, getValuesQuery(tagsOptionsArr));
  }

  return searchParams;
};

export function appendToUrl(
  url: URL,
  key: string,
  array: string[] | undefined
) {
  if (array && array.length > 0) {
    array.forEach((id) => {
      url.searchParams.append(key, id.toString());
    });
  }
}

export function formatSearchParams(searchParams: string) {
  if (!searchParams || searchParams.includes('utm')) {
    return '';
  }
  // split the query string into individual key-value pairs
  const pairs = searchParams.split('&');

  // array to store formatted key-value pairs
  const formattedPairs = pairs.map((pair) => {
    const [key, value] = pair.split('=');
    // decode URI components to handle any encoded characters
    const decodedValue = decodeURIComponent(value);
    // add spacing after commas in the values for readability

    const spacedValue = decodedValue
      .split(',')
      .join(', ')
      .replace(/[-+]/g, ' ');
    const spacedKey = decodeURIComponent(key).split('_').join(' ');
    return `${spacedKey}: ${spacedValue}`;
  });
  // join the formatted pairs with new line characters
  return formattedPairs.join('\n');
}

export function constructSimilarUnitsParams(
  unitId: string,
  price: number,
  designType?: string
) {
  const params = new URLSearchParams();
  params.append('exclude_id', unitId.toString());
  if (designType) {
    params.append('design_types[]', designType);
  }
  params.append('min_dollar_price', (price * 0.8).toFixed());
  params.append('max_dollar_price', (price * 1.2).toFixed());
  params.append('per_page', '12');

  return params.toString();
}
export const storeUTMs = (
  utmSource: string | null,
  utmMedium: string | null,
  utmCampaign: string | null
) => {
  if (utmSource) {
    localStorage.setItem('utm_source', utmSource);
  }
  if (utmMedium) {
    localStorage.setItem('utm_medium', utmMedium);
  }
  if (utmCampaign) {
    localStorage.setItem('utm_campaign', utmCampaign);
  }
};
