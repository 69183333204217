/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect, useState, useContext, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  Loader,
  SelectDropdown,
  RangeSlider,
} from '@orascom/common-components';
import {
  OptionValue,
  UnitPaymentTerms,
  InstallmentFrequencyMapper,
  CountriesEnum,
  UnitDetails as UnitDetailsInterface,
} from '@orascom/api-interfaces';
import styles from './unit-payment-calculator.module.scss';
import {
  Unit as UnitUtils,
  getReservationAmountFromReservationPercent,
  isValidReservationAmount,
} from '../../../utils/unit.utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { usePaymentPlanCalculator, downloadSalesOffer } from '@orascom/utils';
import { useParams } from 'react-router-dom';
import Numeral from 'numeral';
import { CurrencyContext } from '../../../contexts/currency.context';
import InfoIcon from '../../../assets/icons/info.svg';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { analyticsDownloadUnitSaleOfferCustomEvent } from 'apps/orascom-shopper-app/src/utils/analytics-events';
import extractUniqueDurations from 'libs/utils/src/lib/extract-unique-durations';
import { toast } from 'react-toastify';

interface UnitPaymentCalculatorProps {
  saveButton: React.ReactNode;
  terms_and_conditions?: boolean;
  readTerms?: boolean;
  updateReadTermsState?: () => void;
  hideDetails?: boolean;
  comparePage?: boolean;
  reservationPage?: boolean;
  handleSelectPaymentTerms?: (paymentTerms: UnitPaymentTerms) => void;
  updateReservationFeeError?: (reservationFeeErrorState: boolean) => void;
  updateReservationFeeValue?: (reservationFeeValueState: number) => void;
  reservationFeeValue?: number;
  unitCountry: CountriesEnum | undefined;
  unitDetails?: UnitDetailsInterface;
  unitId?: number;
}
export interface PaymentPlanInterface {
  downPayment: string;
  originalUnitPrice: string;
  discount: string;
  unitPriceAfterDiscount: string;
  beforeDelivery: string;
  afterDelivery: string;
  clubhouseFees: string;
  maintenanceFees: string;
  clubhouseInstallments: string;
  totalUnitPrice: string;
  duration?: string;
  frequency?: string;
}

export function UnitPaymentCalculator(
  props: Readonly<UnitPaymentCalculatorProps>
) {
  const [loading, setLoading] = useState(true);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const [error, setError] = useState(false);
  const unitId =
    useParams<{ unitId: string }>().unitId ?? props.unitId?.toString();
  const { rates, currency, isLoading } = useContext(CurrencyContext);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const {
    onSelectDownPayment,
    onSelectFrequency,
    onSelectDuration,
    selectedPaymentTerms,
    setSelectedPaymentTerms,
  } = usePaymentPlanCalculator();

  const { t } = useTranslation();

  async function handleDownloadSales() {
    setLoadingPDF(true);
    try {
      if (props.unitDetails && selectedPaymentTerms) {
        analyticsDownloadUnitSaleOfferCustomEvent({
          position: 'bottom',
          unitId: props.unitDetails.id.toString(),
        });
        await downloadSalesOffer(props.unitDetails, selectedPaymentTerms);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingPDF(false);
    }
  }

  const handleDownloadOmanSalesOffer = () => {
    setLoadingPDF(true);
    if (props.unitDetails) {
      analyticsDownloadUnitSaleOfferCustomEvent({
        position: 'bottom',
        unitId: props.unitDetails.id.toString(),
      });
      UnitUtils.downloadOmanSalesOffer(
        props.unitDetails.id,
        props.unitDetails.name
      )
        .catch((err) => {
          toast.error(err);
          console.log(err);
        })
        .finally(() => setLoadingPDF(false));
    }
  };

  useEffect(() => {
    if (!unitId) {
      return;
    }
    setLoading(true);
    UnitUtils.getUnitPayments(unitId)
      .then((terms) => {
        setPaymentTerms(terms);
        if (props.handleSelectPaymentTerms) {
          props.handleSelectPaymentTerms(terms[0]);
        }
        setSelectedPaymentTerms(terms[0]);
        setError(false);
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
      });
  }, [unitId]);

  useEffect(() => {
    if (props.handleSelectPaymentTerms && selectedPaymentTerms) {
      props.handleSelectPaymentTerms(selectedPaymentTerms);
      if (props.updateReservationFeeValue) {
        props.updateReservationFeeValue(
          selectedPaymentTerms.prereservation_amount
        );
      }
    }
  }, [selectedPaymentTerms]);

  let downPaymentOptions: OptionValue[] = [];
  let installmentFreqOptions: OptionValue[] = [];

  const selectedDownPayment: {
    value: number;
    label: string;
  } | null = selectedPaymentTerms
    ? {
        label: `${selectedPaymentTerms.downpayment_percent}%`,
        value: selectedPaymentTerms.downpayment_percent,
      }
    : null;
  const installmentFreq = selectedPaymentTerms
    ? {
        label:
          InstallmentFrequencyMapper[
            selectedPaymentTerms.installment_frequency
          ],
        value: selectedPaymentTerms.installment_frequency,
      }
    : null;

  if (paymentTerms?.length > 0) {
    downPaymentOptions = paymentTerms.reduce((options: OptionValue[], term) => {
      if (!options.find((opt) => opt.value === term.downpayment_percent)) {
        options.push({
          label: `${term.downpayment_percent}%`,
          value: term.downpayment_percent,
        });
      }
      return options;
    }, []);
    installmentFreqOptions = paymentTerms.reduce(
      (options: OptionValue[], term) => {
        if (!options.find((opt) => opt.value === term.installment_frequency)) {
          options.push({
            label: InstallmentFrequencyMapper[term.installment_frequency],
            value: term.installment_frequency,
          });
        }
        return options;
      },
      []
    );

    // installmentYears = paymentTerms.reduce((prev, current) => {
    //   if (!prev.includes(current.installment_durationin_years)) {
    //     prev.push(current.installment_durationin_years);
    //   }
    //   return prev;
    // }, [] as number[]);
  }

  const convertPricePerCurrency = (price: number): number => {
    if (rates && currency !== selectedPaymentTerms?.currency) {
      const rateKey = `${currency}${selectedPaymentTerms?.currency}`;
      const convertedPrice = price / rates[rateKey];
      return convertedPrice;
    }
    return price;
  };

  const handleReservationFeeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value.replace('%', ''));
    if (props.updateReservationFeeValue && value) {
      const reservationAmount = getReservationAmountFromReservationPercent(
        Number(selectedPaymentTerms?.unit_price),
        value
      );
      props.updateReservationFeeValue(reservationAmount);
    }
  };

  const handleReservationFeeBlur = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace('%', '') + '%';
  };

  const reservationAmount = props.reservationFeeValue;

  let validReservationAmount = false;

  validReservationAmount = isValidReservationAmount(
    props.unitCountry,
    selectedPaymentTerms?.unit_price,
    reservationAmount,
    selectedPaymentTerms?.prereservation_percent,
    selectedPaymentTerms?.prereservation_amount,
    selectedPaymentTerms?.downpayment_percent,
    selectedPaymentTerms?.downpayment_amount
  );

  const reservationFeeValidationError = !validReservationAmount
    ? `Value must be between ${selectedPaymentTerms?.prereservation_percent}% and ${selectedDownPayment?.value}%.`
    : '';

  useEffect(() => {
    if (props.updateReservationFeeError) {
      if (reservationFeeValidationError.length) {
        props.updateReservationFeeError(true);
      } else {
        props.updateReservationFeeError(false);
      }
    }
  }, [props.reservationFeeValue, reservationFeeValidationError]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return (
      <h2 className="orascom__title">No payment plan found for this unit</h2>
    );
  }

  const displayedPaymentDetails = { ...selectedPaymentTerms };

  if (paymentTerms && selectedPaymentTerms) {
    if (currency !== selectedPaymentTerms.currency && !isLoading) {
      displayedPaymentDetails.unit_price = convertPricePerCurrency(
        selectedPaymentTerms.unit_price
      );
      displayedPaymentDetails.discount_amount = convertPricePerCurrency(
        selectedPaymentTerms.discount_amount
      );
      displayedPaymentDetails.price_after_discount = convertPricePerCurrency(
        selectedPaymentTerms.price_after_discount
      );
      if (props.unitCountry !== CountriesEnum.OMAN) {
        displayedPaymentDetails.unit_installment_before_delivery_per_frequency =
          convertPricePerCurrency(
            selectedPaymentTerms.unit_installment_before_delivery_per_frequency
          );
        displayedPaymentDetails.unit_installment_after_delivery_per_frequency =
          convertPricePerCurrency(
            selectedPaymentTerms.unit_installment_after_delivery_per_frequency
          );
        displayedPaymentDetails.first_installment_amount =
          convertPricePerCurrency(
            selectedPaymentTerms.first_installment_amount
          );
        displayedPaymentDetails.delivery_amount = convertPricePerCurrency(
          selectedPaymentTerms.delivery_amount
        );
      } else {
        displayedPaymentDetails.installment_amount = convertPricePerCurrency(
          selectedPaymentTerms.installment_amount as number
        );
      }
      displayedPaymentDetails.clubhouse_fees = convertPricePerCurrency(
        selectedPaymentTerms.clubhouse_fees
      );
      displayedPaymentDetails.maintenance_fees = convertPricePerCurrency(
        selectedPaymentTerms.maintenance_fees
      );
      displayedPaymentDetails.clubhouse_fees_installment_amount =
        convertPricePerCurrency(
          selectedPaymentTerms.clubhouse_fees_installment_amount
        );
      displayedPaymentDetails.total_unit_price = convertPricePerCurrency(
        selectedPaymentTerms.total_unit_price
      );
      displayedPaymentDetails.prereservation_amount = convertPricePerCurrency(
        selectedPaymentTerms.prereservation_amount
      );
      displayedPaymentDetails.downpayment_amount = convertPricePerCurrency(
        selectedPaymentTerms.downpayment_amount
      );
      displayedPaymentDetails.currency = currency;
    }
  }

  const displayedReservationAmount =
    convertPricePerCurrency(
      props.reservationFeeValue ??
        selectedPaymentTerms?.prereservation_amount ??
        0
    ) ?? props.reservationFeeValue;

  const reservationPercent =
    ((props.reservationFeeValue ?? 0) * 100) /
    (selectedPaymentTerms?.unit_price ?? 0);

  const hideUnitPrice =
    !displayedPaymentDetails?.unit_price &&
    !displayedPaymentDetails?.discount_amount &&
    !displayedPaymentDetails?.price_after_discount;

  const showBeforeInstallment =
    Boolean(
      displayedPaymentDetails.total_number_of_installments_before_delivery
    ) &&
    Boolean(
      displayedPaymentDetails?.unit_installment_before_delivery_per_frequency
    );

  const showAfterInstallment =
    Boolean(
      displayedPaymentDetails.total_number_of_installments_after_delivery
    ) &&
    Boolean(
      displayedPaymentDetails?.unit_installment_after_delivery_per_frequency
    );

  const showInstallmentAmount = Boolean(
    displayedPaymentDetails?.installment_amount
  );

  const showDeliveryPercent = Boolean(
    displayedPaymentDetails?.delivery_percent
  );

  const hideUnitInstallments =
    props.unitCountry !== CountriesEnum.OMAN
      ? !displayedPaymentDetails?.first_installment_amount &&
        !showDeliveryPercent &&
        !displayedPaymentDetails?.delivery_amount &&
        !showBeforeInstallment &&
        !showAfterInstallment
      : !showInstallmentAmount && !showDeliveryPercent;

  const installmentDurations = extractUniqueDurations(paymentTerms);

  const showElgounaDisclaimer =
    props.unitDetails?.project.destination.slug === 'el-gouna' &&
    currency === 'EGP';

  const showSalesOfferBtn =
    props.unitDetails?.project.destination.country.slug ===
      CountriesEnum.OMAN ||
    (props.unitDetails?.project.sales_offer && selectedPaymentTerms);

  const comparePageCalculatorItems =
    props.unitCountry === CountriesEnum.OMAN ? (
      <div className={styles['details-item']}>
        {showInstallmentAmount && (
          <div>
            <label>
              {t('installments')} .{' '}
              <small>
                {displayedPaymentDetails.total_number_of_installments}{' '}
                {t('installments')}
              </small>
            </label>
            <p>
              {`${Numeral(displayedPaymentDetails?.installment_amount).format(
                '0,0'
              )} ${displayedPaymentDetails?.currency} `}
              <small>
                /{' '}
                {
                  InstallmentFrequencyMapper[
                    displayedPaymentDetails?.installment_frequency as number
                  ]
                }
              </small>
            </p>
          </div>
        )}
      </div>
    ) : (
      <div className={styles['installments']}>
        <div>
          <label>
            {t('beforeDelivery')} .{' '}
            <small>
              {
                displayedPaymentDetails.total_number_of_installments_before_delivery
              }{' '}
              {t('installments')}
            </small>
          </label>
          <p>
            {`${Numeral(
              displayedPaymentDetails?.unit_installment_before_delivery_per_frequency
            ).format('0,0')} ${displayedPaymentDetails?.currency} `}
            <small>
              /{' '}
              {
                InstallmentFrequencyMapper[
                  displayedPaymentDetails?.installment_frequency as number
                ]
              }
            </small>
          </p>
        </div>

        <div>
          <label>
            {t('afterDelivery')} .{' '}
            <small>
              {
                displayedPaymentDetails.total_number_of_installments_after_delivery
              }{' '}
              {t('installments')}
            </small>
          </label>
          <p>
            {Numeral(
              displayedPaymentDetails?.unit_installment_after_delivery_per_frequency
            ).format('0,0')}{' '}
            {displayedPaymentDetails?.currency}{' '}
            <small>
              /{' '}
              {
                InstallmentFrequencyMapper[
                  displayedPaymentDetails?.installment_frequency as number
                ]
              }
            </small>
          </p>
        </div>
      </div>
    );

  const showDeliveryAmountOrDeliveryPercent =
    showInstallmentAmount || showDeliveryPercent ? (
      <div className={styles['details-item']}>
        {showInstallmentAmount && (
          <div>
            <label>
              {t('installments')} .{' '}
              <small>
                {displayedPaymentDetails.total_number_of_installments}{' '}
                {t('installments')}
              </small>
            </label>
            <p>
              {`${Numeral(displayedPaymentDetails?.installment_amount).format(
                '0,0'
              )} ${displayedPaymentDetails?.currency} `}
              <small>
                /{' '}
                {
                  InstallmentFrequencyMapper[
                    displayedPaymentDetails?.installment_frequency as number
                  ]
                }
              </small>
            </p>
          </div>
        )}
        {showDeliveryPercent && (
          <div>
            <label>
              {t('deliveryAmount')} .{' '}
              <small>{displayedPaymentDetails.delivery_percent}%</small>
            </label>
            <p>
              {`${Numeral(displayedPaymentDetails?.delivery_amount).format(
                '0,0'
              )} ${displayedPaymentDetails?.currency} `}
            </p>
          </div>
        )}
      </div>
    ) : null;

  return (
    <div className="plan">
      <div className={styles['dropdowns']}>
        <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
          <label>
            {t('downPayment')} <small>{t('downpaymentDisclaimer')}</small>
          </label>
          <div className={styles['select-wrapper']}>
            <SelectDropdown
              options={downPaymentOptions}
              placeholder={t('downPayment')}
              onChange={(val) => {
                onSelectDownPayment(val?.value as number, paymentTerms);
              }}
              selectedOption={selectedDownPayment}
            />
            {selectedPaymentTerms && (
              <span className={styles['select-value']}>
                {Numeral(displayedPaymentDetails?.downpayment_amount).format(
                  '0,0'
                )}{' '}
                {displayedPaymentDetails?.currency}
              </span>
            )}
          </div>
        </div>
        {props.reservationPage && props.unitCountry === CountriesEnum.EGYPT ? (
          <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
            <div className={styles['flex']}>
              <label>{t('reservationFees')}</label>
            </div>

            <div className={styles['reservation-fee']}>
              <input
                type="text"
                placeholder={Numeral(reservationPercent).format('0,0.0') + '%'}
                onInput={handleReservationFeeChange}
                onBlur={handleReservationFeeBlur}
                className={styles['reservation-fee__input']}
              />
              {props.reservationFeeValue && selectedPaymentTerms ? (
                <span className={styles['select-value']}>
                  {Numeral(displayedReservationAmount).format('0,0')}{' '}
                  {displayedPaymentDetails?.currency}
                </span>
              ) : null}
              <p className={styles['disclaimer']}>
                <img src={InfoIcon} role="presentation" alt="" />
                <span>{`${t('reservationFeeDisclaimer')} ${
                  displayedPaymentDetails?.prereservation_percent
                }% (${Numeral(
                  displayedPaymentDetails?.prereservation_amount
                ).format('0,0')} ${displayedPaymentDetails?.currency})`}</span>
              </p>
              <p className="error">{reservationFeeValidationError}</p>
            </div>
          </div>
        ) : null}

        {!props.comparePage && (
          <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
            <label>{t('installmentFrequency')}</label>
            <SelectDropdown
              options={installmentFreqOptions}
              placeholder={t('installmentFrequency')}
              onChange={(val) => {
                onSelectFrequency(val?.value as number, paymentTerms);
              }}
              selectedOption={installmentFreq}
            />
          </div>
        )}
        <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
          <div className={styles['flex']}>
            <label>{t('installmentDuration')}</label>
            <h5>
              {displayedPaymentDetails?.installment_durationin_years}{' '}
              {t('years')}
            </h5>
          </div>
          <RangeSlider
            className={styles['range']}
            sliderValues={installmentDurations}
            inputValue={selectedPaymentTerms?.installment_durationin_years}
            onChange={(val) => {
              onSelectDuration(val, paymentTerms);
            }}
            sliderWrapperProps={{ className: styles['range-wrapper'] }}
          />
        </div>
        {props.comparePage ? comparePageCalculatorItems : null}
      </div>
      <div className={styles['info']}>
        {!props.hideDetails && selectedPaymentTerms && (
          <>
            <h3 className="orascom__title">{t('unitPriceBreakdown')}</h3>
            <div className={styles['details']}>
              {hideUnitPrice || (
                <>
                  <h4 className="orascom__sub-title">{t('unitPrice')}</h4>
                  <div className={styles['details-item']}>
                    {Boolean(displayedPaymentDetails?.unit_price) && (
                      <div>
                        <label>{t('originalUnitPrice')}</label>
                        <p>
                          {Numeral(displayedPaymentDetails?.unit_price).format(
                            '0,0'
                          )}{' '}
                          <small className={styles['currency']}>
                            {displayedPaymentDetails?.currency}
                          </small>
                        </p>
                      </div>
                    )}
                    {Boolean(displayedPaymentDetails?.discount_amount) && (
                      <div className={styles['details-item--discount']}>
                        <label>{t('discount')}</label>
                        <p>
                          {Numeral(
                            displayedPaymentDetails?.discount_amount
                          ).format('0,0')}
                          <small className={styles['currency']}>
                            {displayedPaymentDetails?.currency} (
                            {displayedPaymentDetails?.discount_percent}%)
                          </small>
                        </p>
                      </div>
                    )}
                    {Boolean(displayedPaymentDetails?.price_after_discount) && (
                      <div>
                        <label>{t('unitPriceAfterDiscount')}</label>
                        <p>
                          {Numeral(
                            displayedPaymentDetails?.price_after_discount
                          ).format('0,0')}{' '}
                          <small className={styles['currency']}>
                            {displayedPaymentDetails?.currency}
                          </small>
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}

              {hideUnitInstallments || (
                <>
                  <h4 className="orascom__sub-title">
                    {t('unitInstallments')}
                  </h4>
                  {props.unitCountry !== CountriesEnum.OMAN ? (
                    <div className={styles['details-item']}>
                      {displayedPaymentDetails?.first_installment_amount &&
                      displayedPaymentDetails?.first_installment_amount > 0 ? (
                        <div>
                          <label>{t('firstInstallment')}</label>
                          <p>
                            {Numeral(
                              displayedPaymentDetails?.first_installment_amount
                            ).format('0,0')}
                            <small className={styles['currency']}>
                              {' '}
                              {displayedPaymentDetails?.currency} (
                              {
                                displayedPaymentDetails.first_installment_percent
                              }
                              %)
                            </small>
                          </p>
                        </div>
                      ) : null}
                      {displayedPaymentDetails?.delivery_percent &&
                      displayedPaymentDetails.delivery_percent > 0 ? (
                        <div>
                          <label>
                            {t('deliveryAmount')} .{' '}
                            <small>
                              {displayedPaymentDetails.delivery_percent}%
                            </small>
                          </label>
                          <p>
                            {`${Numeral(
                              displayedPaymentDetails?.delivery_amount
                            ).format('0,0')} ${
                              displayedPaymentDetails?.currency
                            } `}
                          </p>
                        </div>
                      ) : null}
                      {showBeforeInstallment && (
                        <div>
                          <label>
                            {t('beforeDelivery')} .{' '}
                            <small>
                              {
                                displayedPaymentDetails.total_number_of_installments_before_delivery
                              }{' '}
                              {t('installments')}
                            </small>
                          </label>
                          <p>
                            {`${Numeral(
                              displayedPaymentDetails?.unit_installment_before_delivery_per_frequency
                            ).format('0,0')} ${
                              displayedPaymentDetails?.currency
                            } `}
                            <small>
                              /{' '}
                              {
                                InstallmentFrequencyMapper[
                                  displayedPaymentDetails?.installment_frequency as number
                                ]
                              }
                            </small>
                          </p>
                        </div>
                      )}
                      {showAfterInstallment && (
                        <div>
                          <label>
                            {t('afterDelivery')} .{' '}
                            <small>
                              {
                                displayedPaymentDetails.total_number_of_installments_after_delivery
                              }{' '}
                              {t('installments')}
                            </small>
                          </label>
                          <p>
                            {Numeral(
                              displayedPaymentDetails?.unit_installment_after_delivery_per_frequency
                            ).format('0,0')}{' '}
                            {displayedPaymentDetails?.currency}{' '}
                            <small>
                              /{' '}
                              {
                                InstallmentFrequencyMapper[
                                  displayedPaymentDetails?.installment_frequency as number
                                ]
                              }
                            </small>
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    showDeliveryAmountOrDeliveryPercent
                  )}
                </>
              )}
              {displayedPaymentDetails?.clubhouse_fees ||
              displayedPaymentDetails?.maintenance_fees ||
              displayedPaymentDetails?.clubhouse_fees_installment_count ? (
                <>
                  <h4 className="orascom__sub-title">
                    {t('clubhouseAndMaintenanceFees')}
                  </h4>

                  <div className={styles['details-item']}>
                    {displayedPaymentDetails?.clubhouse_fees ? (
                      <div>
                        <label>{t('clubhouseFees')}</label>
                        <p>
                          {Numeral(
                            displayedPaymentDetails?.clubhouse_fees
                          ).format('0,0')}{' '}
                          <small className={styles['currency']}>
                            {displayedPaymentDetails?.currency}
                          </small>
                        </p>
                      </div>
                    ) : null}
                    {displayedPaymentDetails?.maintenance_fees ? (
                      <div>
                        <label>{t('maintenanceFees')}</label>
                        <p>
                          {Numeral(
                            displayedPaymentDetails?.maintenance_fees
                          ).format('0,0')}{' '}
                          <small className={styles['currency']}>
                            {' '}
                            {displayedPaymentDetails?.currency}
                          </small>
                        </p>
                      </div>
                    ) : null}
                    {displayedPaymentDetails?.clubhouse_fees_installment_count ? (
                      <div>
                        <label>
                          {t('clubhouseInstallments')} .{' '}
                          <small>
                            {
                              displayedPaymentDetails?.clubhouse_fees_installment_count
                            }{' '}
                            {t('installments')}
                          </small>
                        </label>
                        <p>
                          {Numeral(
                            displayedPaymentDetails?.clubhouse_fees_installment_amount
                          ).format('0,0')}
                          <small className={styles['currency']}>
                            {' '}
                            {displayedPaymentDetails?.currency}
                          </small>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}
              {Boolean(displayedPaymentDetails?.total_unit_price) && (
                <>
                  <h4 className="orascom__sub-title">{t('totalUnitPrice')}</h4>
                  <div
                    className={`${styles['details-item']} ${styles['details-item--span']}`}
                  >
                    <div>
                      <p>
                        {Numeral(
                          displayedPaymentDetails?.total_unit_price
                        ).format('0,0')}{' '}
                        <small className={styles['currency']}>
                          {displayedPaymentDetails?.currency}
                        </small>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {!props.comparePage && (
          <div className={styles['footer']}>
            {Boolean(displayedPaymentDetails?.prereservation_amount) && (
              <div className={styles['reservation-fee']}>
                <p>{t('reservationFee')}</p>
                <p>
                  {displayedPaymentDetails &&
                    Numeral(
                      displayedReservationAmount ??
                        displayedPaymentDetails?.prereservation_amount
                    ).format('0,0')}{' '}
                  <small className={styles['currency']}>
                    {displayedPaymentDetails?.currency}
                  </small>
                </p>
              </div>
            )}
            {props.terms_and_conditions && (
              <div className={styles['terms']}>
                <input
                  type="checkbox"
                  checked={props.readTerms}
                  readOnly
                  name="terms-checkbox"
                  id="terms-checkbox"
                  onClick={props.updateReadTermsState}
                />
                <label htmlFor="terms-checkbox">
                  <span>{t('haveReadAndAccepted')} </span>
                  <span className={styles['terms-link']}>
                    {t('terms&Conditions')}
                  </span>
                  <span>{t('howTheyAffectPrice')}</span>
                </label>
              </div>
            )}
            {props.unitCountry === CountriesEnum.OMAN &&
            displayedPaymentDetails.paymentplan_name
              ?.toLowerCase()
              .includes('local') ? (
              <p className={styles['disclaimer']}>
                <img src={InfoIcon} role="presentation" alt="" />
                <span>{t('omanLocalDisclaimer')}</span>
              </p>
            ) : null}

            {showElgounaDisclaimer && (
              <p
                className={styles['disclaimer']}
                style={{ marginBottom: '25px' }}
              >
                <img
                  src={InfoIcon}
                  role="presentation"
                  alt="disclaimer icon"
                  style={{ height: '20px' }}
                  loading="lazy"
                />
                <span>{t('elGounaDisclaimer')}</span>
              </p>
            )}
            {props.saveButton}
            {/* {showSalesOfferBtn && (
              <button
                className={`${styles['download-btn']}`}
                onClick={() => {
                  if (
                    props.unitDetails?.project.destination.country.slug ===
                    CountriesEnum.OMAN
                  ) {
                    handleDownloadOmanSalesOffer();
                  } else {
                    handleDownloadSales();
                  }
                }}
              >
                {loadingPDF ? <Loader /> : t('downloadSalesOffer')}
              </button>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
}

export default UnitPaymentCalculator;
