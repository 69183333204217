import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Numeral from 'numeral';
import { ROUTES } from '../../api/routes';
import styles from './unit-details.module.scss';
import HeartFilledIcon from '../../assets/icons/heart-filled.svg?react';
import LoveIcon from '../../assets/icons/love.svg?react';
import HabitableIcon from '../../assets/icons/habitable-arrow.svg';
import HouseIcon from '../../assets/icons/house.svg';
import { GoldButton, NavyButton } from '../../components/common/button/button';
import { UNIT_COMPARE_CONTEXT } from '../../contexts/unit-comparison.context';
import {
  handleAddToWishList,
  handleRemoveFromWishList,
} from '../../utils/wishlist.utils';
import { USER_CONTEXT } from '../../contexts/user-context';
import { CurrencyContext } from '../../contexts/currency.context';
import {
  analyticsAddToCompareCustomEvent,
  analyticsDownloadUnitSaleOfferCustomEvent,
  analyticsInterestedUnitCustomEvent,
  analyticsReserveUnitCustomEvent,
} from '../../utils/analytics-events';
import ShareAction from './share-action';
import {
  CountriesEnum,
  UnitDetails,
  UnitDetails as UnitDetailsInterface,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
/* eslint-disable */
import { downloadSalesOffer, useMediaQuery } from '@orascom/utils';
import { Loader, ThumbsGallery } from '@orascom/common-components';
import { detailKeys, spaceKeys } from './unit-info-keys';
import { showReservationButton } from '../../definitions/consts/envConstants';
import { Unit as UnitUtilities } from '../../utils/unit.utils';
import { toast } from 'react-toastify';
/* eslint-enable */

interface SpaceDataProps {
  label: string;
  space: string | number;
}
const SpaceData = ({ label, space }: SpaceDataProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <label>{label}</label>
      <p>
        {space} {t('m²')}
      </p>
    </div>
  );
};

type SetStateBooleanAction = Dispatch<SetStateAction<boolean>>;

interface UnitDataProps {
  unitId?: string;
  unitDetails: UnitDetailsInterface;
  inComparison: boolean;
  wishListed: boolean;
  selectedPaymentTerms?: UnitPaymentTerms;
  setShowModal: SetStateBooleanAction;
  setShowInterestModal: SetStateBooleanAction;
  setWishListed: SetStateBooleanAction;
}
function UnitData(props: Readonly<UnitDataProps>) {
  const {
    unitDetails,
    wishListed,
    setWishListed,
    setShowModal,
    setShowInterestModal,
    inComparison,
    selectedPaymentTerms,
    unitId,
  } = props;

  const { t } = useTranslation();
  const unitContext = useContext(UNIT_COMPARE_CONTEXT);

  const { rates, currency } = useContext(CurrencyContext);
  const isMobile = useMediaQuery('only screen and (max-width : 768px)');
  const userContext = useContext(USER_CONTEXT);

  const navigate = useNavigate();
  const [loadingPDF, setLoadingPDF] = useState(false);

  const handleShowInterestModal = () => {
    setShowModal(true);
    setShowInterestModal(true);
    document.body.style.overflow = 'hidden';
  };
  const location = useLocation();
  async function handleDownloadSales() {
    setLoadingPDF(true);
    try {
      if (unitDetails && selectedPaymentTerms) {
        analyticsDownloadUnitSaleOfferCustomEvent({
          position: 'top',
          unitId: unitDetails.id.toString(),
        });

        await downloadSalesOffer(unitDetails, selectedPaymentTerms);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingPDF(false);
    }
  }

  const handleDownloadOmanSalesOffer = () => {
    setLoadingPDF(true);
    analyticsDownloadUnitSaleOfferCustomEvent({
      position: 'top',
      unitId: unitDetails.id.toString(),
    });
    UnitUtilities.downloadOmanSalesOffer(unitDetails.id, unitDetails.name)
      .catch((err) => {
        toast.error(err);
        console.log(err);
      })
      .finally(() => setLoadingPDF(false));
  };

  const handleAddToCompare = () => {
    if (inComparison) {
      unitContext.removeUnit?.(Number(unitId));
    } else {
      analyticsAddToCompareCustomEvent({
        name: `added unitId: ${unitId}`,
        userId: userContext.user?.id.toString(),
        timestamp: Date.now().toString(),
        portal: 'Shopper',
        pageName: location.pathname,
        country: unitDetails.project.destination.country.slug,
        destination: unitDetails.project.destination.slug,
      });
      unitContext.addUnit?.(Number(unitId));
    }
  };
  const handleWishlist = (unitDetailsId: number) => {
    if (wishListed) {
      handleRemoveFromWishList(
        unitDetailsId,
        setWishListed,
        t('unitRemovedFromWishlist')
      );
    } else {
      if (!userContext.user?.id) {
        navigate(ROUTES.Login.path);
        return;
      }
      handleAddToWishList(
        unitDetailsId,
        setWishListed,
        t('unitAddedToWishlist')
      );
    }
  };

  let displayedUnitCurrency = unitDetails.currency;
  let displayedUnitPrice = unitDetails.price;

  if (rates && unitDetails) {
    if (currency !== unitDetails?.currency) {
      const rateKey = `${currency}${unitDetails?.currency}`;
      displayedUnitCurrency = currency;
      displayedUnitPrice = unitDetails.price / Number(rates[rateKey]);
    }
  }
  const showHabitableSpaceTitle = spaceKeys.some(
    (prop) => unitDetails?.[prop.key as keyof UnitDetails]
  );

  const hasGallery = unitDetails?.gallery.length > 0;

  const showSalesOfferBtn =
    unitDetails?.project.destination.country.slug === CountriesEnum.OMAN ||
    (unitDetails?.project.sales_offer && selectedPaymentTerms);

  return (
    <div className={`${styles['about']} container`}>
      {/* desktop gallery */}
      {hasGallery && !isMobile ? (
        <div className={`${styles['about-gallery']}`}>
          <ThumbsGallery images={unitDetails.gallery} />
        </div>
      ) : null}

      <div className={styles['about-info']}>
        <div className={styles['about-info-headers']}>
          <h1 className={'orascom__title'}>
            {unitDetails.project.name} - {unitDetails.project.destination.name}
          </h1>
          <div className={styles['header-headings--flex']}>
            <h2 className="orascom__sub-title">{unitDetails.unit_type.name}</h2>

            <button
              type="button"
              onClick={() => handleWishlist(unitDetails.id)}
            >
              {wishListed ? <HeartFilledIcon /> : <LoveIcon />}
            </button>

            <ShareAction unitTypeName={unitDetails.unit_type.name} />
          </div>

          {/* mobile gallery */}
          {hasGallery && isMobile ? (
            <>
              <div className={`${styles['about-gallery-mob']}`}>
                <ThumbsGallery
                  images={unitDetails.gallery}
                  featuredSwiperProps={{
                    navigation: false,
                  }}
                  thumbsSwiperProps={{
                    spaceBetween: 4,
                  }}
                />
              </div>
              <h1 className="orascom__title">{t('unitPrice')}</h1>
            </>
          ) : null}

          <h2 className="orascom__sub-title">
            {Numeral(displayedUnitPrice).format('0,0')} {displayedUnitCurrency}
          </h2>
        </div>

        {showHabitableSpaceTitle && (
          <h3 className={styles['about-info-title']}>
            <img src={HabitableIcon} alt="arrow" />
            {t('unitAreas')}
          </h3>
        )}

        <div className={styles['about-info-details']}>
          {spaceKeys.map(({ key, translationKey }) =>
            key && unitDetails[key] ? (
              <SpaceData
                key={key}
                label={t(translationKey)}
                space={unitDetails[key]}
              />
            ) : null
          )}
        </div>

        <h3 className={styles['about-info-title']}>
          <img src={HouseIcon} alt="house" />
          {t('unitDetails')}
        </h3>

        <div className={styles['about-info-details']}>
          {detailKeys.map(
            ({ key, translationKey, transform }) =>
              key &&
              unitDetails[key] && (
                <div key={key}>
                  <label>{t(translationKey)}</label>
                  <p>
                    {transform ? transform(unitDetails[key]) : unitDetails[key]}
                  </p>
                </div>
              )
          )}
        </div>

        <div className={styles['about-info-buttons']}>
          {showReservationButton && (
            <GoldButton
              asLink
              to={ROUTES.ReserverProperty.getPath?.(unitId)}
              onClick={() => {
                analyticsReserveUnitCustomEvent({
                  position: 'top',
                  unitId: unitId,
                });
              }}
            >
              {t('reserveUnit')}
            </GoldButton>
          )}
          <NavyButton
            onClick={() => {
              analyticsInterestedUnitCustomEvent({ unitId: unitId });
              handleShowInterestModal();
            }}
          >
            {t('I’mInterested')}
          </NavyButton>
        </div>
        {/* {showSalesOfferBtn && (
          <button
            className={`${styles['download-btn']}`}
            onClick={() => {
              if (
                unitDetails.project.destination.country.slug ===
                CountriesEnum.OMAN
              ) {
                handleDownloadOmanSalesOffer();
              } else {
                handleDownloadSales();
              }
            }}
          >
            {loadingPDF ? <Loader /> : t('downloadSalesOffer')}
          </button>
        )} */}
        <div className={styles['about-info-checkbox']}>
          <input
            type="checkbox"
            id="add-unit"
            name="add-unit"
            defaultChecked={inComparison}
            onClick={() => handleAddToCompare()}
          />
          <label htmlFor="add-unit">
            {inComparison ? t('addedToComparison') : t('addToCompare')}
          </label>
        </div>
      </div>
    </div>
  );
}

export default UnitData;
